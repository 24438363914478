import React from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import DailyProgressNotes from './DailyProgressNotes';
import Shift from './shift';

function App() {
  return (
    <BrowserRouter key='browser'>
        <Routes key='routes'>
          <Route path='/DailyProgressNotes' element={<DailyProgressNotes/>} key='DailyProgressNotes'/>
          {/* <Route path='/shift' element={<Shift/>} key='shift'/> */}
        </Routes>
    </BrowserRouter>

  );
}

export default App;
