import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Select, DatePicker, Radio, Input, TimePicker, notification, Result, Tour } from 'antd';
import './App.css';

const DailyProgressNotes = () => {
    // const [filteredData, setFilteredData] = useState([]);
    const [formData, setFormData] = useState({
        workerName: '',
        workingType: '',
        workingTime: null,
        serviceType: '',
        worksummary: '',
        activities: [],
        participants: [],
    });
    const [tableData, setTableData] = useState([]);
    const [rawData, setRawData] = useState([]);
    const [employeeOptions, setEmployeeOptions] = useState([]);
    const [participantOptions, setParticipantOptions] = useState([]);
    const [worksummary, setWorkSummary] = useState('');
    const [managementatt, setManagementAtt] = useState('');
    const [showTable, setShowTable] = useState(false);
    const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
    const [password, setPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    const ref7 = useRef(null);
    const ref8 = useRef(null);
    const [open, setOpen] = useState(false);

    const steps = [
        {
            title: 'Staff Name',
            description: 'Please select Staff Name',
            target: () => ref1.current,
        },
        {
            title: 'Position',
            description: 'Please select Staff Position',
            target: () => ref2.current,
        },
        {
            title: 'Service',
            description: 'Please select all the services your provide',
            target: () => ref3.current,
        },
        {
            title: 'Shift Date',
            description: 'Please select shift date, start time and end time',
            target: () => ref4.current,
        },
        {
            title: 'Shift Summary',
            description: 'Please fill the summary of your shift',
            target: () => ref5.current,
        },
        {
            title: 'Management Attention',
            description: 'Any management attention required, if no, please fill N/A',
            target: () => ref6.current,
        },
        {
            title: 'Client Type',
            description: 'Please select client type',
            target: () => ref7.current,
        },
        {
            title: 'Participants',
            description: 'If you are PCA, you need to fill daily progress notes. otherwise, select participiants you served',
            target: () => ref8.current,
        },
    ];

    useEffect(() => {
        if (isAuthenticated) {
            fetch('http://168.138.29.85:5001/participants')
                .then(response => response.json())
                .then(data => {
                    setRawData(data);
                    const participantOptions = data.map(participant => ({
                        value: participant.name,
                        label: participant.name,
                    }));
                    setParticipantOptions(participantOptions);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });

            fetch('http://168.138.29.85:5001/employee')
                .then(response => response.json())
                .then(data => {
                    const employeeOptions = data.map(employee => ({
                        value: employee.workerName,
                        label: employee.workerName,
                    }));
                    setEmployeeOptions(employeeOptions);
                })
                .catch(error => {
                    console.error('Error fetching employee data:', error);
                });
        }
    }, [isAuthenticated]);

    const columnsProportions = {
        name: 0.2,
        note: 0.3,
        otherworker: 0.3,
        rating: 0.2,
    };

    const columns = [
        {
            title: <div className="centered-title">Participant Name</div>,
            dataIndex: 'name',
            width: window.innerWidth * columnsProportions.name,
            align: 'center',
        },
        {
            title: <div className="centered-title">Daily Progress Note</div>,
            dataIndex: 'note',
            render: (note, record) => (
                <Input.TextArea
                    value={note}
                    onChange={(e) => handleTableRowChange(e, record.key, 'note')}
                    required
                />
            ),
            width: window.innerWidth * columnsProportions.note,
        },
        {
            title: <div className="centered-title">Visitors/Other Workers</div>,
            dataIndex: 'otherworker',
            render: (otherworker, record) => (
                <Input.TextArea
                    value={otherworker}
                    onChange={(e) => handleTableRowChange(e, record.key, 'otherworker')}
                    required
                />
            ),
            width: window.innerWidth * columnsProportions.otherworker,
        },
        {
            title: <div className="centered-title">Rating   </div>,
            // <p>If client is "unwell" and needs to be monitored, please select the rating to flag the attention </p>
            dataIndex: 'rating',
            render: (rating, record) => (
                <Select
                    value={rating}
                    placeholder="rating"
                    onChange={(e) => handleTableRowChange(e, record.key, 'rating')}
                    options={[
                        {
                            value: 'Very Unwell',
                            label: 'Very Unwell',
                        },
                        {
                            value: 'Unwell',
                            label: 'Unwell',
                        },
                        {
                            value: 'Average',
                            label: 'Average',
                        },
                        {
                            value: 'Well',
                            label: 'Well',
                        },
                        {
                            value: 'Very Well',
                            label: 'Very Well',
                        },
                        {
                            value: 'Monitor',
                            label: 'Monitor',
                        },
                    ]}
                    style={{ width: '100%' }}
                    required
                >
                </Select>
            ),
            width: window.innerWidth * columnsProportions.rating,
            align: 'center',
        },
    ];

    const handleInputChange = (name, value) => {
        if (name === 'worksummary') {
            setWorkSummary(value);
        } else if (name === 'managementatt') {
            setManagementAtt(value);
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleServiceTypeChange = (value) => {
        handleInputChange('serviceType', value);
        const isPersonalCareAssistance = formData.workingType === 'Personal Care Assistance';
        setShowTable(isPersonalCareAssistance && value !== '');
        const filtered = rawData
            .filter(entry => entry.type === value)
            .map((entry, index) => ({
                key: index,
                name: entry.name,
                note: '',
                otherworker: '',
                rating: '',
            }));

    //     let filtered = rawData
    //     .filter(entry => entry.type === value)
    //     .map((entry, index) => ({
    //         key: index + 1, // Adjusting the key to start from 1
    //         name: entry.name,
    //         note: '',
    //         otherworker: '',
    //         rating: '',
    //     }));

    // // Adding the sentence row as the first entry
    // filtered = [{
    //     key: 0,
    //     name: 'This is a sentence below the table header',
    //     note: 'This is a sentence below the table header',
    //     otherworker: 'This is a sentence below the table header',
    //     rating: 'This is a sentence below the table header',
    // }, ...filtered];


        setTableData(filtered);
    };

    const handleTableRowChange = (e, key, field) => {
        const newValue = e.target ? e.target.value : e;
        const updatedTableData = tableData.map(row => {
            if (row.key === key) {
                return { ...row, [field]: newValue };
            }
            return row;
        });
        setTableData(updatedTableData);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        const missingFields = [];

        if (!formData.workerName) {
            missingFields.push('Worker Name');
        }
        if (!formData.workingType) {
            missingFields.push('Worker Position');
        }
        if (formData.activities.length === 0) {
            missingFields.push('Activities');
        }
        if (!formData.workingTime) {
            missingFields.push('Shift Date');
        }
        if (!formData.startTime) {
            missingFields.push('Shift Start Time');
        }
        if (!formData.endTime) {
            missingFields.push('Shift End Time');
        }
        if (!formData.serviceType) {
            missingFields.push('Service Type');
        }
        if (!worksummary) {
            missingFields.push('Work Summary');
        }
        if (!managementatt) {
            missingFields.push('Management Attention');
        }

        // if (showTable) {
        //     tableData.forEach((row) => {
        //         // if (!row.note || !row.otherworker || !row.rating) {
        //         if (!row.rating) {                    
        //             // missingFields.push(`Participant: ${row.name}, Missing Fields: ${!row.note ? 'Daily Progress Note' : ''} ${!row.otherworker ? 'Visitors/Other Workers' : ''} ${!row.rating ? 'Rating' : ''}`);
        //             missingFields.push(`Participant: ${row.name}, Missing Fields:  ${!row.rating ? 'Rating' : ''}`);
        //         }
        //     });
        // }

        if (missingFields.length > 0) {
            const errorMessage = `Please fill in the: ${missingFields.join(', ')}`;

            notification.error({
                message: 'Validation Error',
                description: errorMessage,
            });
            return;
        }

        const formDataToSubmit = {
            workerName: formData.workerName,
            workingType: formData.workingType,
            activities: formData.activities,
            workingTime: formData.workingTime,
            startTime: formData.startTime,
            endTime: formData.endTime,
            serviceType: formData.serviceType,
            worksummary: worksummary,
            managementatt: managementatt,
            participants: formData.participants,
            tableData: tableData,
        };

        const formDataJSON = JSON.stringify(formDataToSubmit);
        fetch('http://168.138.29.85:5001/progressnote/add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: formDataJSON,
        })
            .then(response => {
                console.log(response);
                if (response.ok) {
                    console.log(response.ok);
                    setIsSubmitSuccessful(true);

                } else {
                    console.error('Error submitting data:', response.status, response.statusText);
                }
            })
            .catch(error => {
                console.error('Error sending request:', error);
            });
    };

    const checkPassword = () => {
        fetch('http://168.138.29.85:5001/progressnote/password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ password }),
        })
            .then(response => {
                if (response.status === 200) {
                    setIsAuthenticated(true);
                } else {
                    alert('Incorrect password. Please try again.');
                    setPassword('');
                }
            })
            .catch(error => {
                console.error('Error verifying password:', error);
            });
    };

    useEffect(() => {
        handleServiceTypeChange(formData.serviceType);
    }, [formData.workingType]);


    const customButtonStyle = {
        backgroundColor: '#f6ffed',
        color: '#135200',
    };

    return (
        <div>
            {!isAuthenticated ? (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
                    <h2>Please enter the password to access this page</h2>
                    <input
                        type="password"
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{ width: '25%', padding: '12px 20px', fontSize: '18px' }}
                    />
                    <br />
                    <Button type="primary" size="large" onClick={checkPassword}>Submit</Button>
                </div>
            ) : (
                <div>
                    {isSubmitSuccessful ? (
                        <Result
                            status="success"
                            title="Data Successfully Submitted"
                            subTitle="Your data has been submitted successfully."
                            extra={[
                                <Button
                                    type="primary"
                                    key="console"
                                    onClick={() => window.location.reload()}
                                >
                                    Back to Form
                                </Button>,
                            ]}
                        />
                    ) : (
                        <div className="label-container" >
                            <h1><span>Sherwood Daily Care Notes</span>
                                <span>
                                    <Button type="default" style={customButtonStyle} size={'large'} onClick={() => setOpen(true)}>
                                        Instruction
                                    </Button>
                                </span>
                            </h1>


                            <div className="form-row">
                                <span className="label" ref={ref1}>Staff Name<span style={{ color: 'red' }}> * </span></span>
                                <Select
                                    placeholder="Select your name"
                                    style={{ width: '60%' }}
                                    showSearch
                                    onChange={(value) => handleInputChange('workerName', value)}
                                    options={employeeOptions}
                                    required
                                >
                                </Select>
                            </div>

                            <div className="form-row">
                                <span className="label" ref={ref2}>Position<span style={{ color: 'red' }}> * </span></span>
                                <Select
                                    name="workingType"
                                    placeholder="Worker Position"
                                    style={{ width: '60%' }}
                                    showSearch
                                    onChange={(value) => handleInputChange('workingType', value)}
                                    required
                                    options={[
                                        {
                                            value: 'Personal Care Assistance',
                                            label: 'Personal Care Assistance',
                                        },
                                        {
                                            value: 'Cleaner',
                                            label: 'Cleaner',
                                        },
                                        {
                                            value: 'Cook',
                                            label: 'Cook',
                                        },
                                        {
                                            value: 'Kitchen Hand',
                                            label: 'Kitchen Hand',
                                        },
                                        {
                                            value: 'Tea Cook',
                                            label: 'Tea Cook',
                                        },

                                        {
                                            value: 'Nurse',
                                            label: 'Nurse',
                                        },
                                        {
                                            value: 'Laundry',
                                            label: 'Laundry',
                                        },
                                        {
                                            value: 'Maintenance',
                                            label: 'Maintenance',
                                        },
                                        {
                                            value: 'Care Manager',
                                            label: 'Care Manager',
                                        },
                                        {
                                            value: 'Activity Coordinator',
                                            label: 'Activity Coordinator',
                                        },
                                    ]}
                                >
                                </Select>
                            </div>

                            <div className="form-row">
                                <span className="label" ref={ref3}>Services<span style={{ color: 'red' }}> * </span></span>
                                <Select
                                    name="activities"
                                    placeholder="Multiple Selection Available"
                                    style={{ width: '60%' }}
                                    showSearch
                                    mode="multiple"
                                    onChange={(value) => handleInputChange('activities', value)}
                                    options={[
                                        {
                                            value: 'Personal Care - oral hygiene',
                                            label: 'Personal Care - oral hygiene',
                                        },
                                        {
                                            value: 'Personal Care - shower',
                                            label: 'Personal Care - shower',
                                        },
                                        {
                                            value: 'Personal Care - skin care',
                                            label: 'Personal Care - skin care',
                                        },
                                        {
                                            value: 'Personal Care - grooming',
                                            label: 'Personal Care - grooming',
                                        },
                                        {
                                            value: 'Personal Care - toileting',
                                            label: 'Personal Care - toileting',
                                        },
                                        {
                                            value: 'Personal Care - medicure & pedicure',
                                            label: 'Personal Care - medicure & pedicure',
                                        },
                                        {
                                            value: 'Personal Care - mobility support',
                                            label: 'Personal Care - mobility support',
                                        },
                                        {
                                            value: 'Personal Care - monitor and report the change of health condition',
                                            label: 'Personal Care - monitor and report the change of health condition',
                                        },
                                        {
                                            value: 'Personal Emotion Support - assisting with de-escalation of aggression or loneliness',
                                            label: 'Personal Emotion Support - assisting with de-escalation of aggression or loneliness',
                                        },
                                        {
                                            value: 'Behaviour Support - assisting development of management plan',
                                            label: 'Behaviour Support - assisting development of management plan',
                                        },
                                        {
                                            value: 'Behaviour Support - assisting with de-escalation of challenging behaviours',
                                            label: 'Behaviour Support - assisting with de-escalation of challenging behaviours',
                                        },
                                        {
                                            value: 'Laundering services - personal clothing (wash and iron)',
                                            label: 'Laundering services - personal clothing (wash and iron)',
                                        },
                                        {
                                            value: 'Laundering services - linen (wash and iron)',
                                            label: 'Laundering services - linen (wash and iron)',
                                        },
                                        {
                                            value: 'Cleaning - private room',
                                            label: 'Cleaning - private room',
                                        },
                                        {
                                            value: 'Cleaning - shared activity room and lounge',
                                            label: 'Cleaning - shared activity room and lounge',
                                        },
                                        {
                                            value: 'Cleaning - shared dinning area',
                                            label: 'Cleaning - shared dinning area',
                                        },
                                        {
                                            value: 'Cleaning - shared corridor',
                                            label: 'Cleaning - shared corridor',
                                        },
                                        {
                                            value: 'Maintenance - gardening and landscaping',
                                            label: 'Maintenance - gardening and landscaping',
                                        },
                                        {
                                            value: 'Maintenance - private request (please specify in shift summary)',
                                            label: 'Maintenance - private request (please specify in shift summary)',
                                        },
                                        {
                                            value: 'Meal - breakfast preparation, delivery and tidy up',
                                            label: 'Meal - breakfast preparation, delivery and tidy up',
                                        },
                                        {
                                            value: 'Meal - morning tea preparation, delivery and tidy up',
                                            label: 'Meal - morning tea preparation, delivery and tidy up',
                                        },
                                        {
                                            value: 'Meal - lunch preparation, delivery and tidy up',
                                            label: 'Meal - lunch preparation, delivery and tidy up',
                                        },
                                        {
                                            value: 'Meal - afternoon tea preparation, delivery and tidy up',
                                            label: 'Meal - afternoon tea preparation, delivery and tidy up',
                                        },
                                        {
                                            value: 'Meal - dinner preparation, delivery and tidy up',
                                            label: 'Meal - dinner preparation, delivery and tidy up',
                                        },
                                        {
                                            value: 'Meal - supper preparation, delivery and tidy up',
                                            label: 'Meal - supper preparation, delivery and tidy up',
                                        },
                                        {
                                            value: 'Medication Administration - morning round',
                                            label: 'Medication Administration - morning round',
                                        },
                                        {
                                            value: 'Medication Administration - mid day round',
                                            label: 'Medication Administration - mid day round',
                                        },
                                        {
                                            value: 'Medication Administration - afternoon round',
                                            label: 'Medication Administration - afternoon round',
                                        },
                                        {
                                            value: 'Medication Administration - evening round',
                                            label: 'Medication Administration - evening round',
                                        },
                                        {
                                            value: 'Medical Attention - wound management',
                                            label: 'Medical Attention - wound management',
                                        },
                                        {
                                            value: 'Medical Attention - monitor healthy and wellbeing status',
                                            label: 'Medical Attention - monitor healthy and wellbeing status',
                                        },
                                        {
                                            value: 'Other Medical Emergency - please specify in daily progress note',
                                            label: 'Other Medical Emergency - please specify in daily progress note',
                                        },
                                        {
                                            value: 'Emergency Management - please specify in daily progress note',
                                            label: 'Emergency Management - please specify in daily progress note',
                                        },
                                        {
                                            value: 'Sleepover and After hour care',
                                            label: 'Sleepover and After hour care',
                                        },
                                        {
                                            value: 'Reporting Duties - incidents and complaints',
                                            label: 'Reporting Duties - incidents and complaints',
                                        },
                                        {
                                            value: 'Other Care Duties - daily care notes',
                                            label: 'Other Care Duties - daily care notes',
                                        },
                                        {
                                            value: 'Other Care Duties - support plan',
                                            label: 'Other Care Duties - support plan',
                                        },
                                        {
                                            value: 'Other Care Duties - NDIS strenght & risk assessment',
                                            label: 'Other Care Duties - NDIS strenght & risk assessment',
                                        },
                                        {
                                            value: 'Other Care Duties - coordinating with external service provider',
                                            label: 'Other Care Duties - coordinating with external service provider',
                                        },
                                        {
                                            value: 'Other Care Duties - NDIS support plans',
                                            label: 'Other Care Duties - NDIS support plans',
                                        },
                                        {
                                            value: 'Other Care Duties - assist with plan review',
                                            label: 'Other Care Duties - assist with plan review',
                                        },
                                        {
                                            value: 'Other Care Duties - care group meeting',
                                            label: 'Other Care Duties - care group meeting',
                                        },
                                        {
                                            value: 'Other Care Duties - appointments (Dr, Families and other)',
                                            label: 'Other Care Duties - appointments (Dr, Families and other)',
                                        },
                                    ]}
                                    required
                                >
                                </Select>
                            </div>

                            <div className="form-row">
                                <span className="label" ref={ref4}>Shift Date<span style={{ color: 'red' }}> * </span></span>
                                <DatePicker
                                    format="DD-MM-YYYY"
                                    placeholder="Select Date"
                                    style={{ width: '11%' }}
                                    onChange={(dates) => handleInputChange('workingTime', dates)}
                                    inputReadOnly
                                    className="center-placeholder centered-dropdown"
                                    required
                                />

                                <span className="label">Shift Start Time<span style={{ color: 'red' }}> * </span></span>
                                <TimePicker
                                    format='HH:mm'
                                    style={{ width: '9%' }}
                                    onChange={(startTime) => handleInputChange('startTime', startTime)}
                                    inputReadOnly
                                    className="center-placeholder centered-dropdown"
                                    required
                                />

                                <span className="label">Shift End Time<span style={{ color: 'red' }}> * </span></span>
                                <TimePicker
                                    format='HH:mm'
                                    style={{ width: '9%' }}
                                    onChange={(endTime) => handleInputChange('endTime', endTime)}
                                    inputReadOnly
                                    className="center-placeholder centered-dropdown"
                                    required
                                />
                            </div>

                            <div className="form-row">
                                <span className="label" ref={ref5}>Shift Summary<span style={{ color: 'red' }}> * </span></span>
                                <Input.TextArea
                                    value={worksummary}
                                    style={{ width: '60%' }}
                                    onChange={(e) => handleInputChange('worksummary', e.target.value)}
                                    required
                                />
                            </div>

                            <div className="form-row">
                                <span className="label" ref={ref6}>Management Attention<span style={{ color: 'red' }}> * </span></span>
                                <Input.TextArea
                                    value={managementatt}
                                    style={{ width: '60%' }}
                                    onChange={(e) => handleInputChange('managementatt', e.target.value)}
                                    required
                                />
                            </div>

                            <div className="form-row">
                                <span className="label" ref={ref7}>Clients Type<span style={{ color: 'red' }}> * </span></span>
                                <Radio.Group
                                    name="Service Type"
                                    style={{ width: '60%' }}
                                    onChange={(e) => handleServiceTypeChange(e.target.value)}
                                    required
                                >
                                    <Radio value="NDIS">NDIS</Radio>
                                    <Radio value="SRS">SRS</Radio>
                                    <Radio value="Age Care">Age Care</Radio>
                                </Radio.Group>
                            </div>

                            <div className="form-row">
                                {showTable ? (
                                    <Table columns={columns} dataSource={tableData} pagination={false}     title={() => (
                                        <div>
                                            <h2 style={{ fontWeight: 'bold', color: 'red' }}>If client is "unwell" and needs to be monitored, please select the rating to flag the attention</h2>
                                        </div>
                                    )}/>
                                ) : (
                                    <span className="label">Participants<span style={{ color: 'red' }}> * </span></span>
                                )}
                                {showTable ? null : (
                                    <Select
                                        name="participant list"
                                        placeholder="Multiple Selection Available"
                                        style={{ width: '60%' }}
                                        showSearch
                                        mode="multiple"
                                        onChange={(value) => handleInputChange('participants', value)}
                                        options={participantOptions}
                                        required
                                    >
                                    </Select>
                                )}
                            </div>

                            <br />
                            <Button type="primary" onClick={handleFormSubmit}>
                                Submit
                            </Button>

                            <Tour
                                open={open}
                                onClose={() => setOpen(false)}
                                steps={steps}
                                indicatorsRender={(current, total) => (
                                    <span>
                                        {current + 1} / {total}
                                    </span>
                                )}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default DailyProgressNotes;

